import $ from "jquery";

export default function () {
	const $header = $(".js-header");
	const $headerLinks = $(".js-header-link");
	const $submenuButtons = $(".js-header-submenu-btn");
	const $submenuButtonsLevel2 = $(".js-header-submenu-level-2");
	const $searchBtn = $(".js-header-search-btn");
	const $searchForm = $("#searchform");
	let timeout = null;

	const showSubmenu = ($submenu) => {
		const height = $submenu.stop().show().outerHeight();
		// $($submenu.find(".js-header-submenu-level-2")[0]).trigger("mouseenter");
		$submenu.css({ height: 0, opacity: 1 }).animate({ height }, 600, function () {});
	};

	const hideSubmenu = () => {
		const $submenu = $(`.js-header-submenu:visible`);
		$submenu.animate({ opacity: 0 }, 150, function () {
			$submenu.css({ height: "auto" }).hide();
			$(".js-submenu-level3-title").hide();
			$(".js-submenu-level-2").hide();
		});
	};

	$submenuButtons.on("mouseenter", function (e) {
		e.preventDefault();
		const id = $(this).attr("data-submenu");
		const alreadyOpened = $(".js-header-submenu:visible") && $(".js-header-submenu:visible").attr("data-submenu") === id;
		clearTimeout(timeout);

		if (!alreadyOpened) {
			const $submenu = $(`.js-header-submenu[data-submenu="${id}"]`);
			hideSubmenu();
			showSubmenu($submenu);
		}
	});

	$headerLinks.on("mouseenter", function () {
		if (!$(this).hasClass("js-header-submenu-btn")) {
			hideSubmenu();
		}
		$(this).removeClass("out").addClass("in");
	});

	$headerLinks.on("mouseleave", function () {
		$(this).removeClass("in").addClass("out");
	});

	$header.on("mouseleave", function () {
		hideSubmenu();
	});

	$searchForm.find('input[type="text"]').attr("placeholder", "Recherche...");

	$submenuButtonsLevel2.on("mouseenter", function () {
		const $button = $(this);
		const id = $button.attr("data-submenu");
		const text = $button.text();
		const $submenu = $button.closest(".js-header-submenu");
		const $level3menus = $submenu.find(".js-submenu-level-2");
		$level3menus.hide();
		$submenu.find(".js-submenu-level3-title").text(text).hide().fadeIn(500);
		console.log($(`.js-submenu-level-2[data-submenu="${id}"]`));
		$(`.js-submenu-level-2[data-submenu="${id}"]`).fadeIn(500);
	});

	$searchBtn.on("click", function () {
		$searchBtn.stop();
		if ($searchForm.width() === 0) {
			const screenWidth = $(document).width();
			const searchWidth = screenWidth > 768 ? 550 : 280;
			$searchForm.animate({ width: searchWidth + "px", opacity: 1 }, 300);
			$(".c-header__nav__links").addClass("blured");
			$(".c-header__nav__cta").animate({ opacity: 0 }, 300);
			$searchForm.find('input[type="text"]').focus();
		} else {
			$searchForm.animate({ width: "0px", opacity: 0 }, 300);
			$(".c-header__nav__links").removeClass("blured");
			$(".c-header__nav__cta").animate({ opacity: 1 }, 300);
		}
	});
}
