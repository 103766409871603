import "./modules/helpers";
import mobileNav from "./modules/mobileNav";
import loader from "./modules/loader";
import scroller from "./modules/scroller";
import header from "./modules/header";
import file from "./modules/file";
import buttons from "./modules/buttons";
import banners from "./modules/banners";
import categories from "./modules/categories";
import archives from "./modules/archives";
import accordeons from "./modules/accordeons";
import quotes from "./modules/quotes";
import postsSlider from "./modules/postsSlider";
import contactform from "./modules/contactform";

mobileNav();
loader();
scroller();
header();
file();
buttons();
banners();
categories();
archives();
accordeons();
quotes();
postsSlider();
contactform();
