import $ from "jquery";
import "slick-carousel";

export default function () {
  $(".js-accordeon").each(function () {
    const $accordeon = $(this);
    $accordeon.on("click", function () {
      if ($accordeon.hasClass("active")) {
        $accordeon
          .next(".js-accordeon-content")
          .slideUp()
          .prev(".js-accordeon");

        setTimeout(() => {
          $accordeon.removeClass("active");
        }, 250);
      } else {
        $accordeon.next(".js-accordeon-content").delay(300).slideDown();
        $accordeon.addClass("active");
      }
    });
  });
}
