import $ from "jquery";

export default function () {
  $(".c-post blockquote").each(function () {
    $(
      this
    ).append(`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 496.4 360.4" style="enable-background:new 0 0 496.4 360.4;" xml:space="preserve">
<path id="Path_1204" style="fill:#F7F7F7;" d="M112.8,0.1C93.7,1,75,6.6,58.6,16.5C31.7,32.3,12.2,58.1,4.4,88.3c-8.5,31.6-4.7,62.5,11.7,89
	l0.1,0.2c16.5,25.9,42.6,43.6,75.4,52.4c6.3,1.7,14.5,1.1,21.8,1.6c-3.7,12.8-7.4,24.3-20.3,39.9c-15.1,18.3-52.9,49.1-52.9,49.1
	c-6.7,5.4-7.7,15.1-2.3,21.8c0,0,0,0,0,0c2.1,2.5,4.9,4.4,8,5.2l46.3,12.4c3.7,1,7.7,0.6,11.1-1.2c0,0,29-14.7,59.5-44.2
	c31.7-30.6,54.1-75.2,66.1-119.9c12.1-45.2,11.7-86.3-3-121.5C211.1,38,183.5,13.8,146.9,3.9C135.8,1,124.3-0.3,112.8,0.1z
	 M371.9,0.1c-19.2,0.9-37.8,6.5-54.2,16.5c-27.3,16.3-46.2,41.1-54.4,71.7c-8.4,31.6-4.5,62.5,11.8,89l0.2,0.2
	c16.5,25.9,42.6,43.6,75.4,52.4c6.3,1.7,14.5,1.1,21.6,1.6c-3.7,12.8-7.4,24.3-20.2,39.9c-15.2,18.3-52.8,49.1-52.8,49.1
	c-6.7,5.4-7.7,15.1-2.3,21.8c0,0,0,0,0,0c2.1,2.5,4.8,4.3,8,5.2l46.3,12.4c3.7,0.9,7.6,0.5,11-1.2c0,0,29-14.7,59.5-44.2
	c31.7-30.6,54.2-75.2,66.2-119.9c12.1-45.2,11.6-86.3-3.1-121.5C470.1,38,442.6,13.8,406,3.9C394.9,1,383.4-0.3,371.9,0.1L371.9,0.1
	z"/>
</svg>
`);
  });
}
