import $ from "jquery";
import "slick-carousel";

export default function () {
  const categories = [];
  let activeCategories = [];
  $(".js-archive-post").each(function () {
    const $post = $(this);
    const categoryId = $post.attr("data-category");
    const categoryName = $post.attr("data-category-name");
    if (!categories.includes(categoryId)) {
      categories.push(categoryId);
      const $button = $(`<button
      class="c-blog__categories__button js-archive-button"
      data-category="${categoryId}"
    >
      ${categoryName}
    </button>`);
      $button.on("click", function () {
        if (activeCategories.includes(categoryId)) {
          $button.removeClass("active");
          activeCategories = activeCategories.filter((c) => c !== categoryId);
        } else {
          $button.addClass("active");
          activeCategories.push(categoryId);
        }

        $(`.js-archive-post`).each(function () {
          const $category = $(this);
          const category = $category.attr("data-category");
          if (activeCategories.length === 0) {
            $category.stop().show();
          } else {
            if (activeCategories.includes(category)) {
              $category.stop().show();
            } else {
              $category.hide();
            }
          }
        });
      });
      $(".js-archive-categories").append($button);
    }
  });
}
