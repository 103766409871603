import $ from "jquery";

export default function() {
    /**
     * Disable WPCF7 button while it's submitting
     * Stops duplicate enquiries coming through
     */
    document.addEventListener( 'wpcf7submit', function( event ) {
        // find only disbaled submit buttons
        var button = $('form button');

        // enable the button
        button.prop('disabled', false);
    }, false );

    $('form.wpcf7-form').on('submit',function() {
        var form = $(this);
        var button = form.find('button');

        // disable the button
        button.prop("disabled", true);
    });
}