import $ from "jquery";
import "slick-carousel";

export default function () {
  $(".js-banner-slider").each(function () {
    const $slider = $(this);
    const $parent = $slider.parent();
    const $prevArrow = $parent.find(".js-banner-slider-prev");
    const $nextArrow = $parent.find(".js-banner-slider-next");

    $slider.slick({
      dots: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      prevArrow: $prevArrow,
      nextArrow: $nextArrow,
      rows: 0,
    });
  });
}
