import $ from "jquery";
import "slick-carousel";

export default function () {
  let activeCategories = [];

  $(".js-category-button").on("click", function () {
    const $button = $(this);
    const category = $button.attr("data-category");
    if (activeCategories.includes(category)) {
      $button.removeClass("active");
      activeCategories = activeCategories.filter((c) => c !== category);
    } else {
      $button.addClass("active");
      activeCategories.push(category);
    }

    $(`.js-category`).each(function () {
      const $category = $(this);
      const category = $category.attr("data-category");
      if (activeCategories.length === 0) {
        $category.stop().show();
      } else {
        if (activeCategories.includes(category)) {
          $category.stop().show();
        } else {
          $category.hide();
        }
      }
    });
  });

  $(".js-category-slider").each(function () {
    const $slider = $(this);
    const $parent = $slider.parent();
    const $prevArrow = $parent.find(".js-category-slider-prev");
    const $nextArrow = $parent.find(".js-category-slider-next");

    $slider.slick({
      dots: false,
      slidesToShow: 3,
      slidesToScroll: 1,
      prevArrow: $prevArrow,
      nextArrow: $nextArrow,
      responsive: [
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    });
  });
}
