import $ from "jquery";

export default function () {
  const $buttons = $(".js-button-arrow");
  const arrowPadding = 50;
  let lastFullWidth = 0;

  $buttons.on("mouseenter", function () {
    const $button = $(this);
    const fullWidthPx = $button.outerWidth() + "px";
    $button.stop().css("width", "auto");
    const autoWidthPX = $button.outerWidth() + arrowPadding + "px";
    lastFullWidth = fullWidthPx;
    $button.css("width", fullWidthPx);
    $button.animate({ width: autoWidthPX }, 120);
  });

  $buttons.on("mouseleave", function () {
    const $button = $(this);
    $button.animate({ width: lastFullWidth }, 120, function () {
      $button.css("width", "");
    });
  });
}
